body {
  /* font-family: "Merriweather", serif; */
  font-family: "Sedan", serif;
  /* font-family: "proxima-nova", sans-serif; */
  /* background: #e1e4f5; */
  background: #edf2fa;
  /* #efe8fa */
  color: #1f1235;
}

.swal2-popup {
  font-family: "Work Sans", sans-serif;
}

input {
  font-family: "Work Sans", sans-serif;
}
.number {
  font-family: "Montserrat", sans-serif;
}

:root {
  --shadow-color: 40deg 61% 44%;
  --shadow-elevation-low:
    0px 0.8px 0.8px hsl(var(--shadow-color) / 0.77),
    0px 3.2px 3.2px -3.4px hsl(var(--shadow-color) / 0.56);
  --shadow-elevation-medium:
    0px 0.8px 0.8px hsl(var(--shadow-color) / 0.72),
    0px 3.5px 3.5px -1.7px hsl(var(--shadow-color) / 0.59),
    0px 15.8px 15.6px -3.4px hsl(var(--shadow-color) / 0.46);
  --shadow-elevation-high:
    0px 0.8px 0.8px hsl(var(--shadow-color) / 0.8),
    0px 5.7px 5.6px -0.8px hsl(var(--shadow-color) / 0.71),
    0px 15.8px 15.6px -1.7px hsl(var(--shadow-color) / 0.63),
    0.1px 38.8px 38.4px -2.6px hsl(var(--shadow-color) / 0.54),
    0.2px 82.3px 81.5px -3.4px hsl(var(--shadow-color) / 0.46);
}

#top-banner {
  width: 100%;
  top: 0;
  left: 0;

  position: absolute;
  padding-top: 10px;
  padding-bottom: 20px;

  background-image: linear-gradient(
    180deg,
    hsl(222deg 92% 90%) 0%,
    hsl(221deg 82% 93%) 50%,
    hsl(217deg 57% 95%) 100%
  );
}

#company-name {
  display:inline;
  position: relative;
  font-size: 70px;
  font-family: "Space Grotesk", sans-serif;
  margin: 0;
  color: black;
  cursor: pointer;

}

#sign-up {
  width: 12%;
  height: 6vh;
  border-radius: 10px;
  float: right;
  border: 1px solid black;

  font-size: 15px;
  margin-right: 40px;
  margin-top: 20px;

  background: #b8c1ec;
  /* color: #232946; */
  cursor: pointer;

  box-shadow: 4px 4px black;
}


#contact-provider {
  width: 12%;
  height: 6vh;
  border: 1px solid black;
  font-size: 15px;

  margin-left: 40px;
  margin-top: 20px;
  float: left;

  background: #eebbc3;
  box-shadow: 4px 4px black;
  border-radius: 10px;
  cursor: pointer;

}

/* /////////////////////
        MAIN
///////////////////// */

#main {
  position: relative;
  top: 20vh;
}

#main #header {
  font-size: 35px;
  margin-left: 20%;
  position: static;

  font-family: "Merriweather", serif;
  color: #232946;
}

#main #input {
  width: 55%;
  background: #fffffe;
  height: 50vh;
  float: right;
  margin-right: 100px;
  position: relative;
  top: 7vh;

  border-radius: 35px;
  border: 3px solid #ffc354;
  box-shadow: 15px 15px #b8c1ec;
}

#main input {
  width: 75%;
  position: relative;
  top: 8vh;
  margin-bottom: 4vh;
  height: 35px;
  border-radius: 3px;
  border: 2px solid #232946;
  padding-left: 10px;
  font-size: 16px;
}

#dropdown {
  top: 23.5vh;
  right: 9.5%;
  position: absolute;
  border-radius: 3px;
  z-index: 10;
  max-height: 33vh;
  overflow: auto;
  /* display: none; */
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  background-color: #f6f6f6;
  border: 1px solid #ddd;
  z-index: 1;
  width: 80.8%;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  text-align: left;
  font-family: "Work Sans", sans-serif;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #f1f1f1;}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {display:block;}

#main #search {
  position: relative;
  top: 15vh;
  width: 82%;
  height: 40px;
  border-radius: 3px;
  border: 2px solid black;
  cursor: pointer;

  background: #5fb4b7;
  color: #153031;
  font-size: 17px;
  transition: all 1s ease-in-out;
  font-family: "Space Grotesk", sans-serif;
}

#main #search:hover {
  border: 3px dotted black;
  transition: all 0.5s ease-in-out;
}

/* /////////////////////
        CHATBOT
///////////////////// */

#chatbox {
    position: fixed;
    bottom: 0;
    height: 50%;
    right: 30px;
    width: 20%;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    border-radius: 8px 8px 0 0;
    overflow: hidden;
    font-size: 14px;
    z-index: 100;
    display: none;
}

.chatbox-header {
    background-color: #4CAF50;
    color: #fff;
    padding: 10px;
    position: relative;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    color: white;
    cursor: pointer;
}

.chatbox-messages {
    height: 200px;
    overflow-y: auto;
    padding: 10px;
    background: #f9f9f9;
}

.message {
    margin-bottom: 15px;
}

.agent, .guest {
    display: flex;
    align-items: flex-start;
}

.agent-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.name {
    font-weight: bold;
}

.agent .text, .guest {
    background-color: #e7e7e7;
    padding: 10px;
    border-radius: 4px;
}

.guest {
    background-color: #d1edc1;
    align-self: flex-end;
}

/* /////////////////////
        RESULTS
///////////////////// */

#results {
  position: relative;
  top: 15vh;
}

#results #choose-insurance {
  width: 65%;
  background: white;
}

#results #input {
  margin: 35px;
  width: 50%;

  top: 25vh;
}

#results input {
  height: 40px;
  width: 40%;
  padding-left: 20px;
  border: 2px solid black;
  font-size: 16px;
  margin-bottom: 30px;
}

#results-dropdown {
  top: 28vh;
  right: 19.4%;
  position: absolute;
  border-radius: 3px;
  z-index: 10;
  max-height: 33vh;
  width: 30%;
  overflow: auto;
  /* display: none; */
}

#results #re-search {
  width: 90%;
  position: relative;
  height: 40px;
  border-radius: 3px;
  border: 2px solid black;
  cursor: pointer;

  background: #5fb4b7;
  color: #153031;
  font-size: 17px;
  transition: all 1s ease-in-out;
  font-family: "Space Grotesk", sans-serif;
}

#results #change-insurance {
  width: 90%;
  position: relative;
  height: 40px;
  border-radius: 3px;
  border: 2px solid black;
  margin-top: 15px;
  cursor: pointer;

  background: #aaceb0;
  color: #153031;
  font-size: 17px;
  transition: all 1s ease-in-out;
  font-family: "Space Grotesk", sans-serif;
}

#results #filters {
  border: 1px solid black;
  width: 35%;
  height: 55vh;
  margin-left: 35px;

  position: relative;
  top: 23vh;
}

.draw-border {
	 color: black;
	 transition: color 0.25s 0.0833333333s;
	 position: relative;
}
 .draw-border::before, .draw-border::after {
	 border: 0 solid transparent;
	 box-sizing: border-box;
	 content: '';
	 pointer-events: none;
	 position: absolute;
	 width: 0;
	 height: 0;
	 bottom: 0;
	 right: 0;
}
 .draw-border::before {
	 border-bottom-width: 1px;
	 border-left-width: 1px;
}
 .draw-border::after {
	 border-top-width: 1px;
	 border-right-width: 1px;
}
 .draw-border:hover::before, .draw-border:hover::after {
	 border-color: black;
	 transition: border-color 0s, width 0.25s, height 0.25s;
	 width: 100%;
	 height: 100%;
}
 .draw-border:hover::before {
	 transition-delay: 0s, 0s, 0.25s;
}
 .draw-border:hover::after {
	 transition-delay: 0s, 0.25s, 0s;
}

.center {
  height: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wave {
  width: 5px;
  height: 100px;
  /* background: linear-gradient(45deg, cyan, #fff);
   */
   background: black;
  margin: 10px;
  animation: wave 1s linear infinite;
  border-radius: 20px;
}
.wave:nth-child(2) {
  animation-delay: 0.1s;
}
.wave:nth-child(3) {
  animation-delay: 0.2s;
}
.wave:nth-child(4) {
  animation-delay: 0.3s;
}
.wave:nth-child(5) {
  animation-delay: 0.4s;
}
.wave:nth-child(6) {
  animation-delay: 0.5s;
}
.wave:nth-child(7) {
  animation-delay: 0.6s;
}
.wave:nth-child(8) {
  animation-delay: 0.7s;
}
.wave:nth-child(9) {
  animation-delay: 0.8s;
}
.wave:nth-child(10) {
  animation-delay: 0.9s;
}

@keyframes wave {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

/* The search field */
#myInput {
 box-sizing: border-box;
 background-position: 14px 12px;
 background-repeat: no-repeat;
 font-size: 16px;
 padding: 14px 20px 12px 45px;
 border: none;
 border-bottom: 1px solid #ddd;
}

/* The search field when it gets focus/clicked on */
#myInput:focus {
  outline: 3px solid #ddd;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
 position: relative;
 display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
 display: none;
 position: absolute;
 background-color: #f6f6f6;
 min-width: 230px;
 border: 1px solid #ddd;
 z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
 color: black;
 padding: 12px 16px;
 text-decoration: none;
 display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #f1f1f1}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {display:block;}

#results #plan {
  position: relative;
}

#results #data {
  width: 60%;
  height: 80vh;
  top: 20%;
  right: 15px;
}

#results #data-header {
  font-size: 45px;
  position: relative;
  margin-bottom: 30px;
  bottom: 10px;
}

#results #data {
  margin-top: 20px;
  overflow: auto;
}

.card {
    border: 3px solid black;
    margin-bottom: 20px;
    border-radius: 10px;
    width: 75%; /* Adjust as needed */
    margin-top: 10px;
}

.header {
    display: flex;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    background: #fffffe;
    padding: 15px;
}

.price-info {
    text-align: right;
}

.hospital-name {
    font-size: 18px;
    font-weight: bold;
}

.cash-price,
.your-price {
    display: block;
    color: #333;
}

.add-insurance-button {
  width: 105%;
  padding: 2.5px 5px;
  margin-top: 5px;
  font-size: 15px;
  text-align: center;
  color: #232946;
  border: 2px solid #232946;
  border-radius: 5px;
  background-color: transparent;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;

  transition: .3s ease-in-out
}

.add-insurance-button:hover {
  background: #232946;
  color: white;
}

.your-price {
    color: green;
    font-weight: bold;
}

.service-info {
    margin-bottom: 20px;
}

.service-title {
    font-size: 16px;
    font-weight: bold;
    margin: 0 0 10px 0;
}

.service-address {
    margin: 0;
    color: #666;
}

.distance {
    display: block;
    color: #333;
    margin-top: 5px;
    float: right;
    margin-right: 10px;
}

.appointment-btn {
    background-color: #eebbc3;
    color: black;
    border: none;
    width: 55%;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-bottom: 30px;
    cursor: pointer;
    border-radius: 4px;
    font-family: "Space Grotesk", sans-serif;
}

/* /////////////////////
    MAKE APPOINTMENT
///////////////////// */

#appointment {
  position: absolute;
  top: 15%;
}

#appointment #user-data {
  width: 30%;
  margin-left: 11vw;
  float: left;
  font-family: "Work Sans", sans-serif;
  position: relative;
}

#appointment .user-data-input {
  width: 75%;
  height: 40px;
  margin-top: 30px;
  border: 2.5px solid black;
  border-radius: 10px;
  padding-left: 10px;

  font-weight: 550;
  user-select: none;
  font-size: 16px;

  outline: none;
  transition: all .3s ease-in-out;
}

#appointment .user-data-input:focus {
  box-shadow: 3px 3px #232946;
}

#appointment #appointment-availability {
  float: right;
  margin-right: 8vw;

  width: 40%;
  font-family: "Work Sans", sans-serif;
}

#appointment .appointment-header {
  font-size: 18px;
}

#appointment-availability .appointment-date-first {
  margin-top: -2px;
}

#appointment-availability .appointment-date {
  margin-top: 25px;
}

#appointment-availability .appointment-input-first {
  width: 100%;
  height: 35px;
  cursor: pointer;

  /* top-left | top-right | bottom-right | bottom-left */
  border-radius: 4px 4px 0 0;
  border: 2px solid black;

  border-bottom: none;
  background-color: white;
  text-align: left;

  font-size: 18px;
  margin-top: 15px;
  padding-top: 10px;
  padding-left: 10px;

}

#appointment-availability .appointment-input {
  width: 100%;
  height: 35px;
  cursor: pointer;

  /* top-left | top-right | bottom-right | bottom-left */
  border-radius: 0 0 4px 4px;
  border: 2px solid black;

  background-color: white;
  text-align: left;

  font-size: 18px;
  /* margin-top: 5px; */
  padding-top: 10px;
  padding-left: 10px;
}

#appointment #book-btn {

  width: 23.5%;
  height: 40px;

  position: relative;
  top: 6vh;
  margin-left: 11vw;

  border: 1px solid black;
  border-radius: 8px;
  cursor: pointer;
  background: #ffd87d;

}

#appointment #exit-book-appointment {
  position: relative;
  margin-left: 5vw;
  top: 6.8vh;
  width: 13.5%;
  height: 25px;
  cursor: pointer;
}

@media screen and (min-device-width: 1700px){
  #main-img {
    float:left;
    position: relative;

    left: 5%; 
  }
}

/* /////////////////////
        MOBILE
///////////////////// */

@media screen and (max-width: 800px) {
  #company-name {
    font-size: 50px;
  }

  #top-banner {
    width: 105%;
  }

  #contact-provider {
    display: none;
  }

  #sign-up {
    float: right;
    width: 100px;
    position: absolute;
    top: 30px;
    display: none;
  }

  #main {
    top: 15vh;
    margin: 0;
  }

  #main #header {
    font-size: 35px;
    position: relative;
    float: right;
    width: 90%;

    font-family: "Merriweather", serif;
    color: #232946;
  }

  #main #input {
    width: 95%;
    margin-right: 10px;
    height: 40vh;
  }

  #main-img {
    display: none;
  }

  #results input {
    width: 86vw;
    padding-left: 10px;
    position: relative;
    left: -100px;
  }

  #results #input {
    position: relative;
    top: -30px;
  }

  #results #results-treatment {
    position: relative;
    left: -85px;
  }

  #results #re-search {
    width: 90vw;
    position: relative;
    left: -85px;
  }

  #results #data {
    width: 100vw;
  }

  #results .card {
    width: 90vw;
  }

  #results-dropdown {
    top: 13.5vh;
    left: -85px;
    width: 89.5vw;
  }

  #appointment {
    width: 100vw;
    margin-bottom: 100px;
  }

  #user-data {
    width: 90vw;
    position: absolute;
  }

  #appointment {
    position: absolute;
    top: 10%;
  }

  #appointment #user-data {
    width: 100%;
    margin-left: 8vw;
    float: left;
    font-family: "Work Sans", sans-serif;
    position: relative;
  }

  datedreamer-calendar {
    width: 100%;
    position: absolute;
    right: 0px;
    margin-top: 50px;
    left: 80px;
  }

  #appointment #book-btn {
    position: relative;
    top: 40vh;
    width: 85%;
    margin-bottom: 10vh;
    left: -20px;
  }

  .appointment-date {
    position: relative;
    top: 300px;
    width: 100%;
    right: 126%;
  }

  #appointment-availability .appointment-input-first {
    position: relative;
    /* right: ; */
    width: 90vw;
  }

  #appointment-availability .appointment-input {
    position: relative;
    width: 90vw;
  }

  #appointment #exit-book-appointment {
    width: 50vw;
    position: relative;
    top: .5%;
  }

  #appointment-hdr {
    position: relative;
    top: 20px;
  }


}
